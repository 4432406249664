import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import './login/login.css';
import LoginFooter from './login/loginFooter';

const Messages = ({ errorMessage, modalMessage, redirectTimer }) => {
  const [isModalOpen, setIsModalOpen] = useState(true);

  useEffect(() => {
    if (redirectTimer === 0) {
      setIsModalOpen(false);
    }
  }, [redirectTimer]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  function redirectTimes(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  const redirectToLogin = () => {
    window.location.href = process.env.REACT_APP_LOGIN_URL
    //process.env.REACT_APP_ENV == "development" ? window.location.href = 'https://staging.inclusional.com' : window.location.href =`https://site.inclusional.com`
    // setIsModalOpen(false);
  }

  return (

    <div className='login'>
        <div className='login-bottom'>
            <div className='container-fluid p-0'>
              <div className='bg-login'>
                <img src="/images/logo.svg" className='logo' />
                  <div className='container'>
                    <div className='row d-flex justify-content-between  w-100 h-100'>
                      <div className='col-md-6'>
                        <img src='/images/login-bg.png' alt="login" className='w-100 d-none p-5' />
                      </div>
                      <div className='col-md-6'>
                       
                      </div>
                    </div>
                  </div> 
              </div> 
            </div>
        </div>
        <LoginFooter></LoginFooter>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Modal"
          className='videoModal bg-white w-25 text-center'
        >
          {/* Render error message */}
          {errorMessage && (
            <div className="error-message">
              <h3 className='font-16 mb-4'>{errorMessage}</h3>
              <button className="btn btn-md btn-primary" onClick={redirectToLogin}>Click here to go home</button>
            </div>
          )}

          {/* Render modal message and redirection timer */}
          {modalMessage && (
            <>
              <h3 className='font-16 mb-4'>{modalMessage}</h3>
              <p>We are redirecting to the login page in</p>
              <span className='login-time otp-timer'>{redirectTimes(redirectTimer)}</span>
            </>
          )}
        </Modal>
    </div>
  );
};

export default Messages;
