import React, { useState, useEffect } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";
import apiService from '../../../services/apiService'
import { useNavigate, useLocation} from 'react-router-dom';
import Modal from 'react-modal';
import CustomToast from '../../Toast';
import Loader from '../../layouts/loader';

const Admin = () => {

    const [show, setShow] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [courseLicenseList, setCourseLicenseList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [userData, setUserData] = useState()
    const [selectedValue, setSelectedValue] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [totalCount, setTotalCount] = useState(0)
    const percentage = 0;
    const navigate = useNavigate();


    useEffect(() => {
        // window.scrollTo(0, 0);
        let userDetail = sessionStorage.getItem("userDetail")
        if(userDetail) {
            userDetail = JSON.parse(userDetail)
            setUserData(userDetail)
        }
        setLoader(true);
        fetchCourseLicenses(userDetail);
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, [currentPage])


    const fetchCourseLicenses = (userDetail) => {
        setLoading(true);
        setLoader(true);
        const token = apiService.getAuthToken()
        const org_id = userDetail?.org_id || localStorage.getItem('ORGID')
        apiService.get(`get_org_user_admin_list/${org_id}?pageSize=50&page=${currentPage}`, token).then(
            (response) => {
            // setCourseLicenseList((prevData) => [...new Set([...prevData, ...response?.data || []])]);
            setCourseLicenseList((prevData) => {
                const uniqueOrgs = response?.data?.filter(
                  (org) => !prevData.some((prevOrg) => prevOrg.org_id === org.org_id)
                ) || [];
                return [...prevData, ...uniqueOrgs];
              });
            setLoading(false);
            setLoader(false);
            },
            (error) => {
            setLoading(false);
            setLoader(false);
            console.error("Error fetching data", error);
            // CustomToast.error("Error while fetching course license list")
            }
        );
    };


    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        validateEmail(newEmail);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    }; 

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailPattern.test(email);
        if (!isValid) {
            setEmailError('Please enter a valid email address.');
        } else {
            setEmailError('');
        }
        return isValid
    };

    const handlePhoneChange = (e) => {
        const newPhoneNumber = e.target.value;
        setPhone(newPhoneNumber);
        validatePhone(newPhoneNumber);
    };

    const validatePhone = (phone) => {
        const phonePattern = /^[0-9]{10}$/;
        const isValid = phonePattern.test(phone);
        if (!isValid) {
            setPhoneError('Please enter a valid 10-digit phone number.');
        } else {
            setPhoneError('');
        }
        return isValid
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if(email && !validateEmail(email)) {
            return
          }
          if (phone && !validatePhone(phone)) {
              return;
          }
          
        let orgData = localStorage.getItem('orgData')
        if(orgData) {
            orgData = JSON.parse(orgData)
        }
        setLoader(true);
        const payload = {
            orgid: orgData?.org_id,
            org_name: orgData?.org_name,
            users: [
                {
                    "fullname": name,
                    "email": email,
                    "mobile": phone
                }
            ]
        }
        const token = apiService.getAuthToken()
        localStorage.setItem("inviteData", JSON.stringify(payload))
        apiService.post("send-org-link", payload, token).then((response)=> {
            if (response && response.status === "success") {
                setCourseLicenseList([])
                fetchCourseLicenses()
                CustomToast.success('Invitation sent successfully')
                setLoader(false);
                // openModal()
              }
              setLoader(false);
            //   openModal()
              setName('')
              setEmail('')
              setPhone('')
        }).catch((error)=> {
            setLoader(false);
            // openModal()
            setName('')
            setEmail('')
            setPhone('')
            if(error && error.message && error.message.includes('User already exist')) {
                CustomToast.info('User already exist.')
              }
              else {
                CustomToast.error('Something went wrong.')
              }
           
            // CustomToast.error('Error while sending invitation request')
        })
    };

    const handleScroll = () => {
        const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
        const scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
        const clientHeight = document.documentElement.clientHeight || window.innerHeight;
        const scrollTrigger = 0.9;
    
        if (scrollTop + clientHeight >= scrollHeight * scrollTrigger && !loading && totalCount > currentPage) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    }

    function onSearch(e) {
        const value = e.target.value
        if(value) {
            const filteredOptions = courseLicenseList.filter(option =>
                option.name.toLowerCase().includes(value.toLowerCase())
            );
            setCourseLicenseList([])
            setCourseLicenseList(filteredOptions)
        } else {
            setCourseLicenseList([])
            fetchCourseLicenses(localStorage.getItem('ORGID'))
            setCurrentPage(1)
        }
    }

    const sendOrgInvite = ()=> {
        setShow(false)
    }

    const handleLogout = () => {
        // Clear the token from local storage
        localStorage.removeItem('authToken');
        localStorage.removeItem("selectedCourseObj")
        localStorage.removeItem("selectedCourse")
        localStorage.removeItem("orgData")
        sessionStorage.removeItem("userDetail")
        localStorage.removeItem("ORGID")
        localStorage.removeItem("user_details")
        localStorage.removeItem("inviteCourseList")
        localStorage.removeItem("image")
        localStorage.removeItem("inviteData")
        localStorage.removeItem("selectedLanguageInfo")
        // if(process.env.REACT_APP_ENV == "development") {
        //     window.location.href = `https://staging.inclusional.com`;
        //   } else {
        //     window.location.href = `https://site.inclusional.com`;
        // }
        window.location.href = process.env.REACT_APP_LOGIN_URL
    };

    const handleDeleteUser = (userId, uid) => {
        let user_details = sessionStorage.getItem('userDetail')
        if(user_details) {
            user_details = JSON.parse(user_details)
        }
        const token = apiService.getAuthToken()
        const obj = {uid: uid}
        apiService.delete(`remove-org-role/${userId}`, obj, token).then((res)=> {
            if(res && res?.status == "success") {
                if(user_details?.uid == uid) {
                    CustomToast.success('Org Admin has been deleted')
                    setTimeout(() => {
                        handleLogout()
                    }, 1000);
                } else {
                    CustomToast.success('Org Admin has been deleted')
                    setCourseLicenseList([])
                    fetchCourseLicenses(userData)
                }
            }
        }).catch((err)=> {
            console.log(err)
            // CustomToast.error('Something went wrong')
        })
      };

    
      return (
        <>
            {!show && <h4 className="d-flex justify-content-between fw-bold font-26 mb-4"><span>Assigned Admins</span>
                <a className="text-right text-decoration-none" onClick={() => setShow(true)}>
                    <img src="/images/account_plus.svg" alt="add admin" className="add_btn" /> 
                    <span className="button-default font-20">Add Admin</span>
                </a>
            </h4>}
            
            {show && 
                <div>
                    <h5 className="font-weight-600 font-26 mb-4">Add Admin</h5>
                    <div className="card">
                        <div className="card-body">
                        {loader ? <Loader /> :
                            <div className="">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-4 form-group mb-3">
                                            <label className='mb-2 fw-bold'>Name*</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder='Name'
                                                value={name}
                                                name="name"
                                                onChange={(e) => setName(e.target.value)}
                                                required />
                                        </div>
                                        <div className="col-md-4 form-group mb-3">
                                            <label className='mb-2 fw-bold'>Email Id*</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder='Org. Email ID'
                                                value={email}
                                                name="email"
                                                onChange={handleEmailChange}
                                                required />
                                                {emailError && <p className="error">{emailError}</p>}
                                        </div>
                                        <div className="col-md-4 form-group mb-3">
                                            <label className='mb-2 fw-bold'>Mobile*</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder='xxxxx xxxxx'
                                                value={phone}
                                                name="phone"
                                                onChange={handlePhoneChange}
                                                required />
                                                {phoneError && <p className="error">{phoneError}</p>}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-3 offset-md-9">
                                            <div className='d-flex justify-content-between mt-3'>
                                                <button type='submit' className='btn btn-secondary w-100 me-2' onClick={() => setShow(false)}>Cancel</button>
                                                <button type='submit' className='btn btn-primary w-100 ms-3'>Save</button>
                                            </div>

                                        </div>
                                    </div>
                                </form>
                                                             
                                    <Modal
                                        isOpen={isModalOpen}
                                        onRequestClose={closeModal}
                                        contentLabel="Modal"
                                        className='Modal bg-white p-4'
                                    >
                                        {/*<button type="button" className="close text-white m-2" onClick={closeModal}><svg width="20" height="20" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.548828" width="27.4625" height="27.4517" rx="13.7259" fill="#146B90" />
                                        <path d="M19.619 17.6867C20.0081 18.0758 20.0081 18.6811 19.619 19.0702C19.4245 19.2647 19.1867 19.3512 18.9273 19.3512C18.6679 19.3512 18.4301 19.2647 18.2355 19.0702L14.2796 15.1143L10.3237 19.0702C10.1292 19.2647 9.89141 19.3512 9.63201 19.3512C9.3726 19.3512 9.13482 19.2647 8.94027 19.0702C8.55116 18.6811 8.55116 18.0758 8.94027 17.6867L12.8962 13.7308L8.94027 9.77492C8.55116 9.38582 8.55116 8.78054 8.94027 8.39144C9.32937 8.00233 9.93464 8.00233 10.3237 8.39144L14.2796 12.3473L18.2355 8.39144C18.6246 8.00233 19.2299 8.00233 19.619 8.39144C20.0081 8.78054 20.0081 9.38582 19.619 9.77492L15.6631 13.7308L19.619 17.6867Z" fill="white" /> </svg></button>*/}

                                        <div class="py-4 px-5 w-100 text-center">
                                            <p class="fw-bold font-18 mb-0">Invitation sent successfully.</p>
                                            <button onClick={closeModal} className='btn btn-md btn-primary mt-4 py-2'>Ok</button>
                                        </div>
                                    </Modal>
                            </div>}
                        </div> 
                    </div>
                    <div className='hori_line'></div>
                </div>
            }

            <div class="form-group has-search">
                <span class="form-control-feedback"><img src="/images/search.svg" alt="search" width={35} /></span>
                <input type="text" class="form-control" placeholder="Search" onChange={onSearch}/>
            </div>

            <table class="table table-responsive">
                <thead class="thead-dark">
                    <tr>
                        <th className="text-center">S. No.</th>
                        <th>Admin Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Status</th>
                        <th className='text-center'>Action</th>
                    </tr>
                </thead>
                
              {loader ? <Loader /> 
              :    <tbody>
              {courseLicenseList && courseLicenseList?.map((element, index)=>{
                            return <tr>
                            <td align='center'>{index + 1}</td>
                            <td>{element.name}</td>
                            <td>{element.email}</td>
                            <td>{element.mobile}</td>
                            {element.user_id ? <td>ACCEPTED</td> :  <td>INVITED</td>}
                            {element.user_id ? <td align='center'><img src="/images/delete.svg" onClick={() => handleDeleteUser(element?.org_user_id, element?.user_id)} alt="delete" width={20} height={20} /></td> : <td></td>}
                        </tr>
                      })}
              </tbody>
              }
              
            </table>

        </>
    )
}

export default Admin;