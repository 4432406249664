import { CircularProgressbar } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";
import apiService from '../../../services/apiService';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, Link} from 'react-router-dom';
import CustomToast from '../../Toast';
import Loader from '../../layouts/loader';
import Modal from 'react-modal';
import LanguageSelectModal from '../modal/languageSelectModal'
import GeneralModal from '../modal/generalModal'

const Course = () => {
    const percentage = 0;
    const [courseList, setCourseList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [userInfo, setUserInfo] = useState({})
    const [showFullDescription, setShowFullDescription] = useState({});
    const navigate = useNavigate();
    const [playing, setPlaying] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [videoSrc, setVideoSrc] = useState('');
    const videoRefs = useRef({});
    const limit = 300;
    const [isOpenLangModal, setIsOpenLangModal] = useState(false)
    const [langSpecific, setLangSpecific] = useState(false)
    const [currentCourse, setCurrentCourse] = useState(false)
    const [fromCourseInfo, setFromCourseInfo] = useState(false)
    const [popUpMessage, setPopUpMessage] = useState(false)
    const [isOpenGenModal, setIsOpenGenModal] = useState(false)

    const openModal = (videoSrc) => {
        setVideoSrc(videoSrc);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const openGenModal = () => {
        setIsOpenGenModal(true)
    }

    const closeGeneModal = () => {
        setIsOpenGenModal(false)
    }

    const handleViewMore = (courseId) => {
        setShowFullDescription(prevState => ({
            ...prevState,
            [courseId]: !prevState[courseId]
        }));

    };

    const openLanguageSelectModal = (course, value) => {
        getLmsData(userInfo?.uid, course?.new_course_id).then((data)=> {
            if(data && value) {
                navigate(`/learning_space/course_details/${course?.course_meta_id}`, { state: { course } });
            } else if(data && !value) {
                startCourseList(course)
            }
            else {
                setCurrentCourse(course)
                setIsOpenLangModal(true)
                setFromCourseInfo(value)
            }
        })
    }

    const closeLanguageSelectModal = () => {
        setIsOpenLangModal(false)
    }

    const startCourseList = async (data) => {
        if(data?.is_course_expired) {
            setPopUpMessage('The course you are trying to access, has expired. if you need to extend it, please contact your admin.')
            openGenModal()
            // CustomToast.info('The course you are trying to access, has expired. if you need to extend it, please contact your admin.')
        } else {
            let userDetails = sessionStorage.getItem("userDetail")
            if(userDetails) {
                userDetails = JSON.parse(userDetails)
                userDetails['is_org_user'] = true
            if(data?.lang) {
                localStorage.setItem('userLang', data?.lang)
                }
                const encodedToken = encodeURIComponent(JSON.stringify(userDetails));
                let url
                if(process.env.REACT_APP_ENV == 'development') {
                    url = `https://fstage-lms.inclusional.com/learning_space/course_modules/${data?.course_meta_id}?ref=${encodedToken}`
                } else {
                    url = `https://fstage-lms.inclusional.com/learning_space/course_modules/${data?.course_meta_id}?ref=${encodedToken}`
                }
                const newTab = window.open(url, '_blank');
                // Focus on the new tab (optional)
                if (newTab) {
                newTab.focus();
                } else {
                console.error('Failed to open new tab. Make sure pop-up blocker is disabled.');
                }
            } else {
                window.location.href = process.env.REACT_APP_LOGIN_URL
                // if(process.env.REACT_APP_ENV == 'development') {
                //     window.location.href = "https://staging.inclusional.com"
                // } else {
                //     window.location.href = "https://site.inclusional.com"
                // }
            }
            }
    }

    useEffect(() => {
        // console.log("selectedLanguage",  selectedLanguages)
        const getCourseData = async () => {
          setLoader(true);
          try {
            let user_details = sessionStorage.getItem('userDetail')
            if (user_details) {
                user_details = JSON.parse(user_details)
                setUserInfo(user_details)
            }
            const courseResponse = await getCourseList(user_details);
            const initialShowFullDescription = {};
            courseResponse?.data?.course_list.forEach(course => {
                initialShowFullDescription[course.course_meta_id] = false;
            });
            setShowFullDescription(initialShowFullDescription);
            setCourseList(courseResponse?.data?.course_list);
            getLMSUserCourse(user_details, courseResponse?.data?.course_list)
          } catch (error) {
            console.error('Error fetching courses:', error);
            // Handle errors as needed
          } finally {
            setLoader(false);
          }
        };
        getCourseData(); // Fetch courses initially
      }, 
    []);


    const selectCourse = (id) => {
        sessionStorage('selectedCourse', id)
    }

    const getCourseSelectedLang = () => {
        let currentCourse
        let localLang = localStorage.getItem('selectedLanguageInfo');
        if (localLang) {
            localLang = JSON.parse(localLang);
            getCourseList(userInfo, localLang.lang_id).then((data) => {
                if (data?.data?.course_list && data?.data.course_list.length > 0) {
                    data.data.course_list = data?.data?.course_list.filter((ele => ele.new_course_id === localLang?.course_id));
                    if(data.data.course_list && data.data.course_list.length > 0) {
                        const updatedCourseList = courseList.map((ele) => {
                            const availableCourse = data?.data.course_list.find(c => c.new_course_id === ele.new_course_id);
                            if (availableCourse) {
                                currentCourse = availableCourse
                                return availableCourse;
                            } else {
                                return ele;
                            }
                        });
                        setCourseList(updatedCourseList);
                        setLangSpecific(true);
                        if(fromCourseInfo) {
                            navigate(`/learning_space/course_details/${currentCourse?.course_meta_id}`, { state: { currentCourse } });
                        } else {
                            startCourseList(currentCourse)
                        }
                    } else {
                        CustomToast.info("Course is not available for selected language")
                    }
                }
            });
        }
    };

    useEffect(() => {
        const initialShowFullDescription = {};
        courseList.forEach(course => {
            initialShowFullDescription[course.course_meta_id] = false;
        });
        setShowFullDescription(initialShowFullDescription);
    }, [langSpecific]);

    const getInitialCall = (course_id, lang, user_details, newCourseList) => {
        let currentCourse
        getCourseList(user_details, lang).then((data) => {
            if (data?.data?.course_list && data?.data?.course_list.length > 0) {
                data.data.course_list = data?.data?.course_list.filter((ele => ele.new_course_id === course_id))
                if(data.data.course_list && data.data.course_list.length > 0) {
                    const updatedCourseList = newCourseList.map((ele) => {
                        const availableCourse = data?.data?.course_list.find(c => c.new_course_id === ele.new_course_id);
                        if (availableCourse) {
                            return availableCourse;
                        } else {
                            return ele;
                        }
                    });
                    setCourseList(updatedCourseList);
                }
                // startCourseList(currentCourse)
            }
        });
    };

    const getLMSUserCourse = async (user_details, courseList) => {
        const newCourseList = JSON.parse(JSON.stringify(courseList))
        if (courseList && courseList.length > 0) {
            const allCoursePromises = courseList.map((ele) => {
                return getLmsData(user_details?.uid, ele?.new_course_id);
            });
            try {
                const courseInfo = await Promise.all(allCoursePromises);
                localStorage.setItem('userLangArr', JSON.stringify(courseInfo))
                let count = 0;
                for (let i = 0; i < courseInfo.length; i++) {
                    count++;
                    await getInitialCall(courseInfo[i]?.course_id, courseInfo[i]?.lang_id, user_details, newCourseList);
                }
                if (count === courseInfo.length) {
                    setLangSpecific(true);
                }
            } catch (error) {
                console.error("An error occurred:", error);
            }
        }
    };
    
    const getLmsData = (uid, course_id) => {
        return apiService.get(`get-lms-user-course-info?uid=${userInfo?.uid || uid}&course_id=${course_id}`)
            .then((res) => {
                if (res?.status === "success") {
                    return res.data;
                }
            });
    };

    const getCourseList = (user_details, lang) => {
        const token = apiService.getAuthToken()
        return apiService.getLmsv1(`courseCardList?user_id=${user_details?.uid}&lang_id=${lang || ''}`, token)
    }

    const inProgressCourseList = async (data) => {
        if(data?.is_course_expired) {
            setPopUpMessage('The course you are trying to access, has expired. if you need to extend it, please contact your admin.')
            openGenModal()
        //   CustomToast.info('The course you are trying to access, has expired. if you need to extend it, please contact your admin.')
        } else {
            let userLangs = sessionStorage.getItem("userLangArr")
            if(data?.lang) {
                localStorage.setItem('userLang', data?.lang)
            } else if(userLangs) {
                userLangs = JSON.parse(userLangs)
                const fetchData = userLangs.find(ele => ele.course_id == data?.new_course_id)
                if(fetchData) {
                    localStorage.setItem('userLang', fetchData?.lang)
                }
            }
            let userDetails = sessionStorage.getItem("userDetail")
            if(userDetails) {
                userDetails = JSON.parse(userDetails)
                userDetails['is_org_user'] = true
                const encodedToken = encodeURIComponent(JSON.stringify(userDetails));
                let url
                if(process.env.REACT_APP_ENV == 'development') {
                    url = `https://fstage-lms.inclusional.com/learning_space/course_modules/${data?.course_meta_id}?ref=${encodedToken}`
                } else {
                    url = `https://fstage-lms.inclusional.com/learning_space/course_modules/${data?.course_meta_id}?ref=${encodedToken}`
                }
                const newTab = window.open(url, '_blank');
                // Focus on the new tab (optional)
                if (newTab) {
                newTab.focus();
                } else {
                console.error('Failed to open new tab. Make sure pop-up blocker is disabled.');
                }
            } else {
                window.location.href = process.env.REACT_APP_LOGIN_URL
                // if(process.env.REACT_APP_ENV == 'development') {
                //     window.location.href = "https://staging.inclusional.com"
                // } else {
                //     window.location.href = "https://site.inclusional.com"
                // }
            }
        }
    }

    const courseInformation = async (data) => {
        if(data?.is_course_expired) {
            setPopUpMessage('The course you are trying to access, has expired. if you need to extend it, please contact your admin.')
            openGenModal()
        //   CustomToast.info('The course you are trying to access, has expired. if you need to extend it, please contact your admin.')
        } else {
            let userLangs = sessionStorage.getItem("userLangArr")
            if(data?.lang) {
                localStorage.setItem('userLang', data?.lang)
                navigate(`/learning_space/course_details/${data?.course_meta_id}`, { state: { course: data } })
            } else if(userLangs) {
                userLangs = JSON.parse(userLangs)
                const fetchData = userLangs.find(ele => ele.course_id == data?.new_course_id)
                if(fetchData) {
                    localStorage.setItem('userLang', fetchData?.lang)
                }
                navigate(`/learning_space/course_details/${data?.course_meta_id}`, { state: { course: data } })
            
            } else {
                navigate(`/learning_space/course_details/${data?.course_meta_id}`, { state: { course: data } })
            }
            }
    }

    const getCertificate = async (courseDetails) => {
        let user_details = localStorage.getItem('user_details')
        let data = {
            "course_id":
                // "432380bf-8bc5-4000-95c1-115f9c8e9d86",
                courseDetails?.course_id,
            "send_email": true,
            "user_id": JSON.parse(user_details)?.uid,
            course_name: courseDetails?.course_name,
            course_lang: localStorage.getItem('selectedLanguage')
        }
        const token = apiService.getAuthToken()
        await apiService.postV1(`generate-certificate`, data, token).then((data) => {
            if (data?.status) {
                window.open(data?.data?.certificate_url, "_blank")
            }
        }).catch(error => {
            CustomToast.error(error);
        })
    }

  /*  const togglePlayPause = (courseId) => {
        const video = videoRefs.current[courseId];
        const playBtn = document.getElementById(`playBtn-${courseId}`);

        if (video) {
            if (video.paused) {
                video.play();
                setPlaying(true);
                setShowOverlay(true);
            } else {
                video.pause();
                setPlaying(false);
                setShowOverlay(false);
            }
        }
    }; */

    const getInitials = (name) => {
        return name?.split(' ').map(word => word.charAt(0)).join('').toUpperCase() || '';
    };

    return (
        <> {courseList && courseList.length > 0 ?
            <div className='learning_course'>
                <h5 className='font-weight-600 font-26 mb-4'>Courses</h5>
                {courseList && courseList?.map((course) => {

                    const description = course?.description;
                    const courseId = course?.id;

                    return <div key={courseId} className="card" style={{ marginBottom: "20px" }}>
                        <div className="card-body p-4">
                            {loader ? <Loader />
                                : <>
                                    <div className="row align-items-center">
                                        <div className="col-md-4">

                                            {course.video ? (
                                                <div className="course-container">

                                                    <div className="listing learning_course_video">
                                                        <video
                                                            className='w-100'
                                                            style={{ width: "300px", height: "auto", objectFit: 'fill', borderRadius: '30px' }}
                                                            poster={course?.image}
                                                        >
                                                            <source src={course?.video} type="video/mp4" />
                                                        </video>
                                                        <button id="playBtn" className="play-btn1" onClick={() => openModal(course?.video)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 180 180" fill="none"><g filter="url(#filter0_d_1545_54856)"><path d="M77.4505 113.254V55.3722L116.038 84.3132M90.3132 20C81.8674 20 73.5044 21.6635 65.7016 24.8955C57.8987 28.1276 50.8089 32.8649 44.8369 38.8369C32.7758 50.8979 26 67.2563 26 84.3132C26 101.37 32.7758 117.728 44.8369 129.789C50.8089 135.761 57.8987 140.499 65.7016 143.731C73.5044 146.963 81.8674 148.626 90.3132 148.626C107.37 148.626 123.728 141.85 135.789 129.789C147.85 117.728 154.626 101.37 154.626 84.3132C154.626 75.8674 152.963 67.5044 149.731 59.7016C146.499 51.8987 141.761 44.8089 135.789 38.8369C129.817 32.8649 122.728 28.1276 114.925 24.8955C107.122 21.6635 98.7589 20 90.3132 20Z" fill="white"></path></g><defs><filter id="filter0_d_1545_54856" x="0.718277" y="0.0407453" width="179.19" height="179.19" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix><feOffset dy="5.32247"></feOffset><feGaussianBlur stdDeviation="12.6409"></feGaussianBlur><feComposite in2="hardAlpha" operator="out"></feComposite><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1545_54856"></feBlend><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1545_54856" result="shape"></feBlend></filter></defs></svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <img src={course?.image} alt='course' style={{ width: "300px", height: "auto", objectFit: 'fill', borderRadius: '30px' }} className='img-fluid w-100 card-img' />
                                            )}

                                            <Modal
                                                isOpen={modalIsOpen && videoSrc === course?.video}
                                                onRequestClose={closeModal}
                                                contentLabel="Video Modal"
                                                className="course-video w-75"
                                            >
                                                <button className="btn btn-link close-btn text-end w-100" onClick={closeModal}><svg width="20" height="20" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="0.548828" width="27.4625" height="27.4517" rx="13.7259" fill="#146B90" />
                                                    <path d="M19.619 17.6867C20.0081 18.0758 20.0081 18.6811 19.619 19.0702C19.4245 19.2647 19.1867 19.3512 18.9273 19.3512C18.6679 19.3512 18.4301 19.2647 18.2355 19.0702L14.2796 15.1143L10.3237 19.0702C10.1292 19.2647 9.89141 19.3512 9.63201 19.3512C9.3726 19.3512 9.13482 19.2647 8.94027 19.0702C8.55116 18.6811 8.55116 18.0758 8.94027 17.6867L12.8962 13.7308L8.94027 9.77492C8.55116 9.38582 8.55116 8.78054 8.94027 8.39144C9.32937 8.00233 9.93464 8.00233 10.3237 8.39144L14.2796 12.3473L18.2355 8.39144C18.6246 8.00233 19.2299 8.00233 19.619 8.39144C20.0081 8.78054 20.0081 9.38582 19.619 9.77492L15.6631 13.7308L19.619 17.6867Z" fill="white" />
                                                </svg></button>

                                                <div className="video-container">
                                                    <video
                                                        controls
                                                        autoPlay
                                                        className="w-100"
                                                        src={videoSrc}
                                                    />
                                                </div>
                                            </Modal>
                                        </div>
                                        <div className="col-md-8">
                                            <h3 className="fw-bold text-capitalize font-20 mt-0">{course?.course_name}</h3>
                                            <p className="text-gray font-16 mb-2">{course?.tagline}</p>
                                            <p className='mb-0'>
                                                <span className="text-dark-gray text-capitalize pe-4"><img src="/images/time.svg" className="me-2" />{course?.duration} </span>
                                                <span className="text-dark-gray text-capitalize"><img src="/images/modules.png" className="me-2" />{course?.total_modules} modules</span>
                                            </p>

                                            <div className='font-14 my-5 course-description'>
                                                {showFullDescription[courseId] ? (
                                                    <div dangerouslySetInnerHTML={{ __html: description }} />
                                                ) : (
                                                    <div>
                                                        <div dangerouslySetInnerHTML={{ __html: `${description?.slice(0, 300)}` }} />
                                                        {description?.length > 300 && (
                                                            <button
                                                                type="button"
                                                                className="btn btn-link px-0 text-decoration-none"
                                                                onClick={() => handleViewMore(courseId)}
                                                            >
                                                                {/* showFullDescription[courseId] ? 'View Less' : 'View More' */}
                                                            </button>
                                                        )}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="row">
                                            {course?.status == 'notstarted' && !fromCourseInfo ? 
                                                <div className="col-md-6"> 
                                                  <button type="button" className="btn btn-md btn-secondary w-100 mb-lg-0 mb-3" onClick={()=> openLanguageSelectModal(course, true)}> Course Information </button>
                                                  </div>
                                                   :
                                                   <div className="col-md-6">
                                                     <button type="button" className="btn btn-md btn-secondary w-100 mb-lg-0 mb-3" onClick={() => courseInformation(course)}>Course Information</button>
                                                   {/* <Link
                                                        to={`/learning_space/course_details/${course?.course_meta_id}`}
                                                        state={{course}}
                                                        className="btn btn-md btn-secondary w-100 mb-lg-0 mb-3"
                                                    >
                                                        Course Information
                                                    </Link> */}
                                                    </div>
                                                  }
                                                    {/* <Link
                                                        to={`/learning_space/course_details/${course?.course_meta_id}`}
                                                        state={{course}}
                                                        className="btn btn-md btn-secondary w-100"
                                                    >
                                                        Course Information
                                                    </Link> */}
                                                {/* </div> */}
                                                {/* {course?.status == 'notstarted' || course?.status == 'completed' ? <div className="col-md-6">
                                                    <button type="button" href="/learning_space/course_modules" className="btn btn-md btn-primary w-100" onClick={() => startCourseList(course)}>Get Started</button>
                                                </div> :
                                                    <div className="col-md-6">
                                                        <a href={`/learning_space/course_modules/${course?.course_meta_id}`} className="btn btn-md btn-primary w-100">Continue Course</a>
                                                    </div>
                                                } */}

                                                {course?.status == 'notstarted' && !fromCourseInfo ? 
                                                    <div className="col-md-6">
                                                        <button type="button" className="btn btn-md btn-primary w-100" onClick={()=> openLanguageSelectModal(course, false)}>Get Started</button>
                                                    </div>
                                                    : course?.status == 'notstarted' &&
                                                    <div className="col-md-6">
                                                    <button type="button" className="btn btn-md btn-primary w-100" onClick={()=> startCourseList(course)}>Get Started</button>
                                                </div>
                                                }

                                                {course?.status == 'completed' &&
                                                    <div className="col-md-6">
                                                        <button type="button" className="btn btn-md btn-primary w-100" onClick={() => inProgressCourseList(course)}>Completed</button>
                                                    </div>
                                                }

                                                {course?.status == 'inprogress' &&
                                                    <div className="col-md-6">
                                                        <button  className="btn btn-md btn-primary w-100" onClick={() => inProgressCourseList(course)}>Continue Course</button>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row align-items-center mt-5">
                                        <div className="col-md-4 side_verticalline">
                                            {course?.completed_user_list?.length == 0 ?
                                                <div className="text-center ">
                                                    <img src="/images/account.svg" className="mb-3" />
                                                    <p>Be the first one to complete the course</p>
                                                </div>
                                                : course?.completed_user_list?.length > 25  && course?.completed_user_list?.length <= 30?
                                                <div className="text-center "> <div className='d-flex justify-content-center align-items-start'>
                                                {course?.completed_user_list?.slice(0, 5).map((user, index) => {
                                                    return (
                                                        <span className="mb-2" key={index}>
                                                            {user?.pic && user?.pic !== "null" && user?.pic !== null ? (
                                                                <img src={user?.pic} alt={user?.name} className="mb-2 me-1" style={{ height: "28px", width: "28px", borderRadius: "50%" }} />
                                                            ) : (
                                                                <span className='course-card-initials fw-bolder font-28 mb-2 me-1 text-white'>{getInitials(user?.name)}</span>
                                                            )}
                                                        </span>
                                                    );
                                                })}
                                                {course?.completed_user_list?.length > 5 && (
                                                    <span className="mb-2 count-circle">
                                                        {course?.completed_user_list?.length - 1}+
                                                    </span>
                                                )}
                                            </div>
                                                    <p>Congrats! you are in top {course?.completed_user_list?.length} <span>{course?.completed_user_list?.length > 0 && course?.completed_user_list?.length !== 1 ? 'employees' : 'employee'}</span></p>
                                                </div> :
                                                <div className="text-center "> <div className='d-flex justify-content-center align-items-start'>
                                                {course?.completed_user_list?.slice(0, 5).map((user, index) => {
                                                    return (
                                                        <span className="mb-2" key={index}>
                                                            {user?.pic && user?.pic !== "null" && user?.pic !== null ? (
                                                                <img src={user?.pic} alt={user?.name} className="mb-2 me-1" style={{ height: "28px", width: "28px", borderRadius: "50%" }} />
                                                            ) : (
                                                                <span className='course-card-initials fw-bolder font-28 mb-2 me-1 text-white'>{getInitials(user?.name)}</span>
                                                            )}
                                                        </span>
                                                    );
                                                })}
                                                {course?.completed_user_list?.length > 5 && (
                                                    <span className="mb-2 count-circle">
                                                        {course?.completed_user_list?.length - 1}+
                                                    </span>
                                                )}
                                            </div>
                                                    <p>{course?.completed_user_list?.length} <span>{course?.completed_user_list?.length > 0 && course?.completed_user_list?.length !== 1 ? 'employees' : 'employee'}</span> have completed course so far!</p>
                                                </div>
                                            }
                                        </div>
                                        <div className="col-md-4 side_verticalline">
                                            <div className="text-center">
                                                <div className='progressbar_learner'>
                                                    <CircularProgressbar
                                                        value={course?.completion_percent}
                                                        text={`${course?.completion_percent}%`}
                                                        strokeWidth={10}
                                                    />
                                                </div>
                                                {course?.completion_percent == 100 ? <p>Completed</p> : course?.completion_percent == 0 ? <p>Get Started</p> : <p> In Progress</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="text-center" >
                                                <img src="/images/certificate.svg" className="mb-3" />
                                                {course?.status == 'completed' ? <p style={{ cursor: "pointer", fontWeight: "bolder", color: "#146B90" }} onClick={() => getCertificate(course)}>Download Certificate</p> : <p>Complete the course & Get Certificate</p>}
                                            </div>
                                        </div>
                                    </div>
                                </>}
                        </div>
                    </div>
                })}

                <Modal
                    isOpen={isOpenLangModal}
                    contentLabel="Active Modal"
                >
                    <LanguageSelectModal
                        isOpen={isOpenLangModal}
                        onRequestClose={closeLanguageSelectModal}
                        course={currentCourse}
                        onConfirm={getCourseSelectedLang}
                    />
                </Modal>

                <Modal
                    isOpen={isOpenGenModal}
                    contentLabel="Active Modal"
                    className="w-50 bg-white"
                >
                    <GeneralModal
                        isOpen={isOpenGenModal}
                        message={popUpMessage}
                        onRequestClose={closeGeneModal}
                    />
                </Modal>


            </div>
            : 
            <div className='learning_course'>
                 <h5 className='font-weight-600 font-26 mb-4'>Courses</h5>
                 <p className='fw-bold mt-4'>To invite yourself to a course, follow the same invite process from your dashboard.</p>
            </div>
}    
        </>
    )

}

export default Course;